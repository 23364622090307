<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div class="item-general-form">
      <div>
        <b-row class="align-items-center">
          <b-col
            class="mb-1"
            md="6"
          >
            <ok-text-input
              v-model="form.ar_name"
              :label="`${$t('fields.name')} (${$t(`locales.ar`)})`"
              is-required
              size="md"
            />
          </b-col>
          <b-col
            class="mb-1"
            md="6"
          >
            <ok-text-input
              v-model="form.en_name"
              :label="`${$t('fields.name')} (${$t(`locales.en`)})`"
              is-required
              size="md"
            />
          </b-col>
          <b-col
            class="mb-1"
            md="6"
          >
            <ok-select
              v-model="form.city"
              :label="$t('fields.city')"
              :options="Cities"
              is-required
              size="lg"
            />
          </b-col>
          <b-col
            class="mb-1"
            md="6"
          >
            <ok-text-input

              :label="$t('branches.phone-number')"
              size="md"
            >
              <ok-phone-number-input v-model="form.phone" />
            </ok-text-input>
          </b-col>
          <b-col
            class="mb-1"
            md="6"
          >
            <ok-text-input
              v-model="form.ar_address"
              :label="`${$t('fields.address')} (${$t(`locales.ar`)})`"
              is-required
              size="md"
              textarea
            />
          </b-col>
          <b-col
            class="mb-1"
            md="6"
          >
            <ok-text-input
              v-model="form.en_address"
              :label="`${$t('fields.address')} (${$t(`locales.en`)})`"
              is-required
              size="md"
              textarea
            />
          </b-col>
          <b-col
            v-if="!form.main"
            class="mt-1"
            md="12"
          >
            <b-form-checkbox
              v-model="form.status"
              switch
            >
              {{ $t('store.active') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="platform in socialPlatforms"
            :key="platform"
            md="6"
            sm="12"
          >
            <ok-text-input
              v-if="platform!=='whatsapp'"
              v-model.trim="social[platform]"
              :label="capitalize(platform)"
              :placeholder="`${platform}.com/Username`"
              dir="ltr"
              rules="url"
            />
            <ok-text-input
              v-else
              label="Whatsapp number"

              size="md"
            >
              <ok-phone-number-input v-model.trim="social[platform]" />
            </ok-text-input>
          </b-col>

        </b-row>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { BCol, BFormCheckbox, BRow } from 'bootstrap-vue'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import OkPhoneNumberInput from '@/@bya3/components/inputs/OkPhoneNumberInput'
import Cities from '@/constants/cities'
import OkSelect from '@/@bya3/components/inputs/OkSelect'
import capitalize from 'lodash/capitalize'
import EditBranchMixin from '../mixinx/edit-branch-mixin'

export default {
  name: 'BranchGeneralForm',
  components: {
    OkSelect,
    OkPhoneNumberInput,
    OkTextInput,
    ValidationObserver,
    BRow,
    BFormCheckbox,
    BCol,
  },
  mixins: [EditBranchMixin],
  props: {
    social: {
      type: Object,
      default: () => ({
        facebook: '',
        instagram: '',
        whatsapp: '',
        telegram: '',
      }),
    },
  },
  data() {
    return {
      fetching: false,
      form: {},
      Cities,
    }
  },
  computed: {
    editMode() {
      return !!this.$route.params?.id
    },
    socialPlatforms() {
      return Object.keys(this.social)
    },
  },
  methods: {
    capitalize,
    prepareForSubmit() {
      const keys = ['phone', 'ar_name', 'status', 'en_name', 'ar_address', 'en_address', 'city']
      const values = pick(this.form, keys)
      values.social = this.social
      return {
        ...values,
      }
    },
    getSocialPlaceHolder() {

    },
    async isValid() {
      return this.$refs.form.validate()
    },
  },
}
</script>
