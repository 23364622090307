var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ok-table',{ref:"datatable",attrs:{"title":_vm.$t('branches.title'),"config":_vm.config},on:{"edit":function($event){return _vm.$router.push({name:'owner:branches-process',params:{id:$event.row.id}})},"delete":_vm.handleDelete},scopedSlots:_vm._u([{key:"status-column",fn:function(ref){
var props = ref.props;
return [(props.row.main)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('forms.active'))+" ")]):_c('b-form-checkbox',{attrs:{"switch":"","unchecked-value":0,"checked":props.row.status,"value":1},on:{"input":function($event){return _vm.updateBranch($event,'status',props.row.id)}}})]}},{key:"is_busy-column",fn:function(ref){
var props = ref.props;
return [_c('b-form-checkbox',{attrs:{"switch":"","unchecked-value":0,"checked":props.row.is_busy,"value":1},on:{"input":function($event){return _vm.updateBranch($event,'is_busy',props.row.id)}}})]}},{key:"delivery-column",fn:function(ref){
var props = ref.props;
return [_c('b-form-checkbox',{attrs:{"switch":"","unchecked-value":0,"checked":props.row.delivery,"value":1},on:{"input":function($event){return _vm.updateBranch($event,'delivery',props.row.id)}}})]}},{key:"pickup-column",fn:function(ref){
var props = ref.props;
return [_c('b-form-checkbox',{attrs:{"switch":"","unchecked-value":0,"checked":props.row.pickup,"value":1},on:{"input":function($event){return _vm.updateBranch($event,'pickup',props.row.id)}}})]}},{key:"shipment-column",fn:function(ref){
var props = ref.props;
return [_c('b-form-checkbox',{attrs:{"switch":"","unchecked-value":0,"checked":props.row.shipment,"value":1},on:{"input":function($event){return _vm.updateBranch($event,'shipment',props.row.id)}}})]}},{key:"schedule-column",fn:function(ref){
var props = ref.props;
return [_c('b-form-checkbox',{attrs:{"unchecked-value":0,"checked":props.row.schedule_enabled,"value":1,"switch":""},on:{"input":function($event){return _vm.updateBranch($event,'schedule_enabled',props.row.id)}}})]}}])},[_c('template',{slot:"buttons"},[_c('b-button',{staticClass:"mx-md-1",attrs:{"size":"md","variant":"dark"},on:{"click":function($event){return _vm.$router.push({name:'owner:branches-process'})}}},[_vm._v(" "+_vm._s(_vm.$t('branches.add'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }