<template>
  <div>
    <fetching-data-spinner
      :error="fetchError"
      :loading="fetch"
      :loading-title="$t('general.loading-data')"
      enable-retry
      @retry="init"
    />
    <div v-if="!fetch">
      <validation-observer ref="form">

        <empty-card
          :title="$t(isEdit?'forms.edit':'forms.add')"
        >
          <template slot="title">
            <div
              class="d-flex align-items-center"
            >
              <b-button
                :disabled="submitting"
                variant="link"
                @click="$router.push({name:'owner:branches'})"
              >
                <span class="text-dark">{{ $t('forms.back') }}</span>
              </b-button>
              <b-button
                :disabled="submitting"
                variant="dark"
                @click="submit()"
              >
                <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
                <b-spinner
                  v-if="submitting"
                  small
                />
              </b-button>
            </div>
          </template>
          <b-tabs
            v-model="tab"
            class="mt-3"
            justified
            pills
          >
            <b-tab
              :title="$t('store.general')"
              :title-link-class="isGeneralValid ? '' : 'bg-danger text-white'"
            >
              <branch-general-form
                ref="general"
                v-model="form"
                :active="tab === 0"
                :social="form.social"
              />
            </b-tab>
            <b-tab
              :title="$t('branches.order-setting')"
              :title-link-class="isSettingsValid ? '' : 'bg-danger text-white'"
            >
              <branch-order-setting-form
                ref="orders"
                v-model="form"
                :active="tab === 1"
              />
            </b-tab>
            <b-tab
              :title="$t('branches.shifts')"
              :title-link-class="isShiftsValid ? '' : 'bg-danger text-white'"
            >
              <branch-opening-hours
                ref="shifts"
                v-model="form"
                :active="tab === 2"
              />
            </b-tab>
          </b-tabs>
        </empty-card>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BTab, BTabs,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver } from 'vee-validate'
import ErrorHandler from '@/utils/error-handler'
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import BranchOrderSettingForm from '@/views/branch/components/BranchOrderSettingForm'
import BranchGeneralForm from '@/views/branch/components/BranchGeneralForm'
import BranchApi from '@/api/branch-api'
import BranchOpeningHours from '@/views/branch/components/BranchOpeningHours'

export default {
  name: 'ProcessBranchPage',
  components: {
    BranchOpeningHours,
    BranchGeneralForm,
    BranchOrderSettingForm,
    EmptyCard,
    FetchingDataSpinner,
    ValidationObserver,
    BButton,
    BTabs,
    BTab,
    BSpinner,
  },
  data() {
    return {
      fetch: true,
      fetchError: null,
      modified: false,
      modifiedReady: true,
      form: { status: true },
      submitting: false,
      tab: 0,
      isGeneralValid: true,
      isSettingsValid: true,
      isShiftsValid: true,
    }
  },
  computed: {
    isEdit() {
      return !!this.$route.params?.id
    },
  },
  watch: {
    value(visible) {
      this.error = null
      this.tab = 0
      if (visible) {
        setTimeout(() => {
          this.modifiedReady = true
          this.modified = false
        }, 1000)
      }
    },
    form: {
      deep: true,
      handler() {
        if (this.modifiedReady) {
          this.modified = true
        }
      },
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      if (!this.isEdit) {
        this.fetch = false
        this.form.branches = [this.$store.getters['shop/currentBranchId']]
        return
      }
      const { id } = this.$route.params
      this.fetch = true
      this.fetchError = null
      this.modifiedReady = false
      this.modified = false
      await BranchApi.getById(id).then(async res => {
        this.form = res?.branch ? cloneDeep(res.branch) : { status: 1 }
        this.form.status = !!this.form.status
        // eslint-disable-next-line no-multi-assign
        setTimeout(() => {
          this.modifiedReady = true
          this.modified = false
        }, 1000)
      })
        .catch(e => {
          this.error = ErrorHandler.getErrorDetails(e)
        })
        .finally(() => {
          this.fetch = false
        })
    },
    async submit() {
      await new Promise(resolve => setTimeout(resolve, 200))
      const valid = await (this.$refs.general.$refs.form.validateWithInfo())
      const validOrders = await (this.$refs.orders.$refs.form.validateWithInfo())
      let shifts = {}
      let validShifts = true
      await this.$refs.shifts.submit().then(res => {
        shifts = { ...res }
      }).catch(() => {
        validShifts = false
      })
      if (!valid.isValid || !validOrders.isValid || !validShifts) {
        this.isGeneralValid = valid.isValid
        this.isShiftsValid = validShifts.isValid
        this.isSettingsValid = validOrders.isValid
        this.error = {
          title: this.$tc('errors.field-invalid', 2),
          message: ErrorHandler.getValidationErrors(valid.errors),
        }
        return
      }

      // eslint-disable-next-line no-multi-assign
      this.isGeneralValid = true
      this.isShiftsValid = true
      this.isSettingsValid = true
      const general = this.$refs.general.prepareForSubmit()
      const settings = this.$refs.orders.prepareForSubmit()
      const payload = {
        ...general,
        ...settings,
        shifts,
      }
      if (this.isEdit) payload.id = this.$route.params.id
      this.submitting = true
      BranchApi.process(payload)
        .then(response => {
          this.$notifySuccess(response.message)
          this.$router.push({ name: 'owner:branches' })
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => {
          this.submitting = false
        })
    },
    handleClose() {
      this.$emit('input', false)
    },
  },
}
</script>
<style lang="scss">

.nav {
  &.nav-pills.nav-justified .nav-link {
    border-radius: 0;
  }

  &.nav-pills.nav-justified .nav-link:not(.active) {
    background-color: #D8D6DD;
    border-left: 1px solid #ffffff;
  }
}
</style>
