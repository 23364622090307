<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('branches.title')"
      :config="config"
      @edit="$router.push({name:'owner:branches-process',params:{id:$event.row.id}})"
      @delete="handleDelete"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="$router.push({name:'owner:branches-process'})"
        >
          {{ $t('branches.add') }}
        </b-button>
      </template>
      <template
        v-slot:status-column="{props}"
      >

        <b-badge
          v-if="props.row.main"
          variant="success"
        >
          {{ $t('forms.active') }}
        </b-badge>
        <b-form-checkbox
          v-else
          switch
          :unchecked-value="0"
          :checked="props.row.status"
          :value="1"
          @input="updateBranch($event,'status',props.row.id)"
        />
      </template>
      <template
        v-slot:is_busy-column="{props}"
      >
        <b-form-checkbox
          switch
          :unchecked-value="0"
          :checked="props.row.is_busy"
          :value="1"
          @input="updateBranch($event,'is_busy',props.row.id)"
        />

      </template>
      <template
        v-slot:delivery-column="{props}"
      >
        <b-form-checkbox
          switch
          :unchecked-value="0"
          :checked="props.row.delivery"
          :value="1"
          @input="updateBranch($event,'delivery',props.row.id)"
        />

      </template>
      <template
        v-slot:pickup-column="{props}"
      >
        <b-form-checkbox
          switch
          :unchecked-value="0"
          :checked="props.row.pickup"
          :value="1"
          @input="updateBranch($event,'pickup',props.row.id)"
        />

      </template>
      <template
        v-slot:shipment-column="{props}"
      >
        <b-form-checkbox
          switch
          :unchecked-value="0"
          :checked="props.row.shipment"
          :value="1"
          @input="updateBranch($event,'shipment',props.row.id)"
        />

      </template>
      <template
        v-slot:schedule-column="{props}"
      >
        <b-form-checkbox
          :unchecked-value="0"
          :checked="props.row.schedule_enabled"
          :value="1"
          switch
          @input="updateBranch($event,'schedule_enabled',props.row.id)"
        />

      </template>
    </ok-table>
  </div>
</template>

<script>
import { BButton, BFormCheckbox, BBadge } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import BranchApi from '@/api/branch-api'
import BranchesTableConfig from './config/branches-table-config'

export default {
  components: {
    OkTable,
    BButton,
    BFormCheckbox,
    BBadge,
  },
  data() {
    return {
      config: BranchesTableConfig,
    }
  },
  methods: {
    refreshTable() {
      this.$refs.datatable.loadItems()
    },
    handleDelete(data) {
      if (data.row.main) return
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => BranchApi.delete(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    updateBranch(value, column, id) {
      const data = { id }
      data[column] = value
      BranchApi.process(data)
        .then(response => {
          this.$notifySuccess(response.message)
          this.refreshTable()
        })
        .catch(e => this.$notifyError(e))
    },
    convertToBool(val) {
      return !!val
    },
  },
}
</script>
