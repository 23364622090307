<template>
  <div class="opening-hours-list">
    <template v-for="{dayName, dayIndex} in weekdays">
      <div
        :key="dayIndex"
        class="card mb-2 mb-md-2 mb-lg-0 border border-muted py-1 px-1 px-md-2"
      >
        <b-row class="align-items-center">
          <b-col
            cols="5"
            lg="2"
            class="mb-2 mb-lg-0 day-name-title no-overflow-text"
          >
            {{ $t(`shifts.weekdays.${dayName}`) }}
          </b-col>

          <b-col
            cols="5"
            lg="2"
            class="mb-2 mb-lg-0"
          >
            <div class="d-flex align-items-center">
              <b-form-checkbox
                :checked="!get(dayIndex, 'closed', false)"
                class="light-green-switch"
                inline
                switch
                @change="handleOpenedSwitch(dayIndex, $event)"
              />
              {{ get(dayIndex, 'closed', false) ? $t('shifts.closed') : $t('shifts.opened') }}
            </div>
          </b-col>

          <b-col
            cols="2"
            lg="2"
            class="mb-2 mb-lg-0 text-right no-overflow-text"
          >
            <span
              v-if="showCopyTime(dayIndex)"
              class="shift-link"
              @click="copyTime(dayIndex)"
            >
              <span class="d-none d-xl-inline">{{ $t('shifts.copy-time-all') }}</span>
              <feather-icon
                v-b-tooltip="$t('shifts.copy-time-all')"
                class="d-inline d-xl-none"
                icon="CopyIcon"
                size="16"
              />
            </span>
          </b-col>

          <b-col
            cols="12"
            lg="5"
            class="text-center"
          >
            <template v-for="(_, shiftIdx) in Array(get(dayIndex, 'shifts.length', 1))">
              <b-row
                :key="shiftIdx"
                class="no-gutters align-items-center"
                :class="{'mt-1': shiftIdx > 0}"
              >
                <b-col sm="5">
                  <shift-time-select
                    :value="get(dayIndex, `shifts[${shiftIdx}].time_from`)"
                    :allow24-hours="!shiftIdx && get(dayIndex, `shifts.length`, 1) <= 1"
                    :disabled="get(dayIndex, 'closed', false)"
                    :min-time="shiftIdx > 0 ? get(dayIndex, `shifts[${shiftIdx - 1}].time_to`) : ''"
                    :placeholder="$t('shifts.opens-at')"
                    :rules="get(dayIndex, 'closed', false) ? '' : 'required'"
                    :validation-name="`${$t('shifts.opens-at')}-${dayIndex}-${shiftIdx}`"
                    @input="handleChangeTime(dayIndex, shiftIdx, 'time_from', $event)"
                  />
                </b-col>
                <template v-if="get(dayIndex, `shifts[${shiftIdx}].time_from`) !== '24'">
                  <b-col sm="1">
                    -
                  </b-col>
                  <b-col sm="5">
                    <shift-time-select
                      :value="get(dayIndex, `shifts[${shiftIdx}].time_to`)"
                      :disabled="get(dayIndex, 'closed', false)"
                      :placeholder="$t('shifts.closes-at')"
                      :max-time="!shiftIdx && get(dayIndex, `shifts.length`, 1) > 1 ? get(dayIndex, `shifts[${shiftIdx + 1}].time_from`) : ''"
                      :rules="get(dayIndex, 'closed', false) ? '' : 'required'"
                      :validation-name="`${$t('shifts.closes-at')}-${dayIndex}-${shiftIdx}`"
                      @input="handleChangeTime(dayIndex, shiftIdx, 'time_to', $event)"
                    />
                  </b-col>
                  <b-col sm="1">
                    <span
                      v-if="showSplitHours(dayIndex)"
                      class="shift-link text-nowrap pl-1"
                      @click="splitTime(dayIndex)"
                    >
                      <span class="d-inline d-sm-none d-lg-inline">{{ $t('shifts.split-hours') }}</span>
                      <feather-icon
                        v-b-tooltip="$t('shifts.split-hours')"
                        class="d-none d-sm-inline d-lg-none"
                        icon="ScissorsIcon"
                      />
                    </span>
                    <span
                      v-else-if="shiftIdx === 1"
                      class="shift-link text-nowrap text-danger pl-1"
                      @click="deleteSplitShift(dayIndex)"
                    >
                      <span class="d-inline d-sm-none d-lg-inline">{{ $t('forms.delete') }}</span>
                      <feather-icon
                        v-b-tooltip="$t('forms.delete')"
                        class="d-none d-sm-inline d-lg-none"
                        icon="Trash2Icon"
                      />
                    </span>
                  </b-col>
                </template>
              </b-row>
            </template>
          </b-col>
        </b-row>
      </div>
    </template>
    <div
      v-if="canReset"
      class="d-flex justify-content-end mt-1"
    >
      <span
        class="cursor-pointer text-underline text-danger"
        @click="reset"
      >
        {{ $t('shifts.reset-all-fields') }}
      </span>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import set from 'lodash/set'
import get from 'lodash/get'
import { weekdays } from '../config/shift-constants'
import ShiftTimeSelect from './ShiftTimeSelect'

export default {
  name: 'OpeningHoursList',
  components: {
    ShiftTimeSelect, BRow, BCol, BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    shifts: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    weekdays() {
      return weekdays.map((weekday, idx) => ({ dayName: weekday, dayIndex: idx + 1 }))
    },
    canReset() {
      return weekdays.some((_, dayIdx) => get(this.shifts, `[${dayIdx + 1}].shifts`, []).some(({ time_from, time_to }) => time_from || time_to))
    },
    firstOpenedDayIndex() {
      return this.weekdays.find((_, dayIdx) => !get(this.shifts, `[${dayIdx + 1}].closed`))?.dayIndex
    },
  },
  methods: {
    set(dayIndex, path, value) {
      set(this.shifts, `${dayIndex}.${path}`, value)
      this.$emit('input', this.shifts)
      this.$forceUpdate()
    },
    get(dayIndex, path, defaultValue) {
      return get(this.shifts, `${dayIndex}.${path}`, defaultValue)
    },
    deleteSplitShift(dayIndex) {
      this.set(dayIndex, 'shifts', [this.get(dayIndex, 'shifts[0]', {})])
    },
    copyTime(index) {
      const shifts = this.get(index, 'shifts', [])
      this.weekdays.forEach(({ dayIndex }) => {
        if (!this.get(dayIndex, 'closed', false)) {
          const dayShifts = shifts.map(shift => ({
            ...shift, id: null, day_from: dayIndex, day_to: shift.time_to <= shift.time_from ? Math.max(1, (dayIndex + 1) % 8) : dayIndex,
          }))
          set(this.shifts, `${dayIndex}.shifts`, dayShifts)
        }
      })
      this.$emit('input', this.shifts)
      this.$forceUpdate()
    },
    splitTime(dayIndex) {
      const timeTo = this.get(dayIndex, 'shifts[0].time_to')
      this.set(dayIndex, 'shifts[1].time_from', '')
      this.set(dayIndex, 'shifts[1].time_to', timeTo)
      this.set(dayIndex, 'shifts[0].time_to', '')
      this.checkDayOverlap(dayIndex, 0)
      this.checkDayOverlap(dayIndex, 1)
    },
    handleChangeTime(dayIndex, shiftIdx, timeType, value) {
      const is24Already = this.get(dayIndex, `shifts[${shiftIdx}].time_from`) === '24'
      if (is24Already && timeType === 'time_from') {
        this.set(dayIndex, `shifts[${shiftIdx}].time_to`, '')
      }

      this.set(dayIndex, `shifts[${shiftIdx}].${timeType}`, value)

      const versesTimeType = timeType === 'time_from' ? 'time_to' : 'time_from'
      const versesTime = this.get(dayIndex, `shifts[${shiftIdx}].${versesTimeType}`)

      if (versesTime === value && shiftIdx === 0) {
        this.set(dayIndex, `shifts[${shiftIdx}].time_from`, '24')
        this.set(dayIndex, `shifts[${shiftIdx}].time_to`, value)
        this.set(dayIndex, `shifts[${shiftIdx}].day_to`, Math.max(1, (dayIndex + 1) % 8))
      } else if (timeType === 'time_from' && value === '24') {
        this.$emit('warning:24hours-warning')
        this.set(dayIndex, `shifts[${shiftIdx}].time_to`, '00:00')
        this.set(dayIndex, `shifts[${shiftIdx}].day_to`, Math.max(1, (dayIndex + 1) % 8))
      } else {
        this.checkDayOverlap(dayIndex, shiftIdx)
      }
    },
    checkDayOverlap(dayIndex, shiftIdx) {
      const timeFrom = this.get(dayIndex, `shifts[${shiftIdx}].time_from`)
      const timeTo = this.get(dayIndex, `shifts[${shiftIdx}].time_to`)
      this.set(dayIndex, `shifts[${shiftIdx}].day_from`, dayIndex)

      if (timeFrom && timeTo && this.$moment(timeFrom, 'HH:mm').isAfter(this.$moment(timeTo, 'HH:mm'))) {
        this.set(dayIndex, `shifts[${shiftIdx}].day_to`, Math.max(1, (dayIndex + 1) % 8))
      } else {
        this.set(dayIndex, `shifts[${shiftIdx}].day_to`, dayIndex)
      }
    },
    reset() {
      this.$okConfirm(this.$t('shifts.reset-confirmation'), {
        confirmButtonText: this.$t('shifts.reset-all-fields'),
        showCancel: true,
        preConfirm: () => {
          this.weekdays.forEach(({ dayIndex }) => {
            set(this.shifts, `${dayIndex}.shifts`, [{
              time_from: '', time_to: '', day_from: dayIndex, day_to: dayIndex,
            }])
          })
          this.$emit('input', this.shifts)
          this.$forceUpdate()
        },
      })
    },
    showCopyTime(dayIndex) {
      const closed = this.get(dayIndex, 'closed', false)
      const timeFrom = this.get(dayIndex, 'shifts[0].time_from')
      const timeTo = this.get(dayIndex, 'shifts[0].time_to')

      return (timeFrom || timeTo) && !closed && this.firstOpenedDayIndex === dayIndex
    },
    showSplitHours(dayIndex) {
      const closed = this.get(dayIndex, 'closed', false)
      const timeFrom = this.get(dayIndex, 'shifts[0].time_from')
      const timeTo = this.get(dayIndex, 'shifts[0].time_to')
      const shiftsCount = this.get(dayIndex, 'shifts.length', 1)

      return timeFrom !== '24' && timeFrom && timeTo && shiftsCount <= 1 && !closed
    },
    handleOpenedSwitch(dayIndex, opened) {
      const openedCount = Object.values(this.shifts).filter(shift => !shift.closed).length
      const closed = !opened
      this.set(dayIndex, 'closed', closed)

      if (closed && openedCount <= 1) {
        this.$emit('error:closed-all', true)
        this.$nextTick(() => this.set(dayIndex, 'closed', !closed))
      }

      this.shifts = { ...this.shifts }
    },
    set24HoursToAll(overwrite = true) {
      this.weekdays.forEach(({ dayIndex }) => {
        if (!overwrite) {
          const isOpen = !this.shifts?.[dayIndex]?.closed
          const isFilled = (this.shifts?.[dayIndex]?.shifts ?? []).every(({ time_from, time_to }) => time_from && time_to)
          if (isOpen && isFilled) return
        }

        const dayShifts = [{
          time_from: '24', time_to: '00:00', day_from: dayIndex, day_to: Math.max(1, (dayIndex + 1) % 8),
        }]
        set(this.shifts, `${dayIndex}`, { shifts: dayShifts, closed: false })
      })
      this.$emit('input', this.shifts)
      this.$emit('error:closed-all', false)
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/utils/responsive.scss";

.opening-hours-list {

  & > .card {
    @include respond-to('medium') {
      border: unset!important;
      box-shadow: unset!important;
    }
  }

  .day-name-title {
    font-size: 16px;
    font-weight: 500;
  }

  .no-overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .v-select.shift-time-select:not(.select-size-lg) .vs__dropdown-toggle .vs__selected {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: initial;
  }

  .shift-link {
    text-decoration: underline;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    color: var(--purple);
    padding: 0 5px;
  }

  .shift-time-select {
  }
}
</style>
