<template>
  <validation-provider
    #default="{errors}"
    :name="validationName || placeholder"
    :rules="rules"
    :debounce="200"
  >
    <b-form-group
      :state="errors.length ? false : null"
      class="mb-0"
    >
      <v-select
        :value="value"
        :placeholder="placeholder"
        class="shift-time-select"
        label="text"
        :reduce="o => o.value"
        :options="options"
        :disabled="disabled"
        :clearable="clearable"
        :dir="isRTL ? 'rtl' : 'ltr'"
        :class="`select-size-${size}`"
        :taggable="allowAddManual"
        :filter-by="filterBy"
        @input="$emit('input', $event)"
        @option:created="handleCreateOption"
      />
      <span
        v-if="showErrorMessage"
        class="text-danger"
      >
        {{ errors[0] }}
      </span>
    </b-form-group>
  </validation-provider>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'
import sortBy from 'lodash/sortBy'
import cloneDeep from 'lodash/cloneDeep'
import { shiftTimesOptions } from '../config/shift-constants'

export default {
  name: 'ShiftTimeSelect',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    allow24Hours: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    minTime: {
      type: String,
      default: '',
    },
    maxTime: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    showErrorMessage: {
      type: Boolean,
      default: false,
    },
    allowAddManual: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    allowOverlap: {
      type: Boolean,
      default: false,
    },
    validationName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customOption: null,
    }
  },
  computed: {
    options() {
      let times = cloneDeep(shiftTimesOptions)
      const hour24Option = { text: this.$t('shifts.24-hours'), value: '24' }
      const overlap = this.allowOverlap && !!this.minTime && !!this.maxTime && this.minTime >= this.maxTime

      if (this.customOption) {
        times.push(this.customOption)
      }

      if (this.minTime) {
        times = times.filter(({ value }) => value >= this.minTime || (value <= '12:00' && overlap))
      }

      if (this.maxTime) {
        times = times.filter(({ value }) => value <= this.maxTime || (value >= '12:00' && overlap))
      }

      if (this.allow24Hours) {
        times = [hour24Option].concat(times)
      }

      if (this.customOption) {
        times = sortBy(times, 'value')
      }

      return times
    },
  },
  watch: {
    value() {
      if (this.value && this.options.findIndex(({ value }) => value === this.value) < 0) {
        this.handleCreateOption({ text: this.value })
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.value && this.options.findIndex(({ value }) => value === this.value) < 0) {
        this.handleCreateOption({ text: this.value })
      }
    },
    filterBy(option, label, search) {
      return ((label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) || ((option?.value || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
    },
    handleCreateOption(option) {
      if (!option?.text) return
      const time = this.$moment(option.text, ['HH:mm', 'H', 'HH', 'hh:mm', 'hh:mm A'])

      if (!time.isValid()) {
        this.$notifyError('shifts.selected-time-invalid')
        return
      }

      const timeValue = time.format('HH:mm')

      const overlap = this.minTime && this.maxTime && this.minTime >= this.maxTime

      if ((this.maxTime && ((this.maxTime < timeValue) && !(timeValue >= '12:00' && overlap))) || (this.minTime && ((this.minTime > timeValue) && !(timeValue <= '12:00' && overlap)))) {
        return
      }

      const index = this.options.findIndex(({ value }) => value === timeValue)
      if (index >= 0) {
        setTimeout(() => this.$emit('input', timeValue), 50)
        return
      }

      this.customOption = { text: time.format('hh:mm A'), value: timeValue }
      setTimeout(() => this.$emit('input', timeValue), 50)
    },
  },
}
</script>

<style lang="scss">
.v-select.shift-time-select:not(.select-size-lg) .vs__dropdown-toggle {
  max-height: 38px!important;
  .vs__selected {
    white-space: nowrap;
  }
}
</style>
