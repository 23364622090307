import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import store from '@/store'
import { BUSINESS_FEATURES } from '@/constants/business-types'
import LocalizationService from '@/services/localization-service'

const BranchesTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/branches`,
  rowsPath: 'branches',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'fields.name',
      field: 'ar_name',
    },
    {
      label: 'fields.city',
      field: 'city',
      formatFn: (val: any) => LocalizationService.translate(`cities.${val}`),
    },
    {
      label: 'fields.phone-number',
      field: 'phone',
    },
    {
      label: 'store.active',
      field: 'status',
    },
    {
      label: 'branches.is-busy',
      field: 'is_busy',
    },
    {
      label: 'branches.has-delivery',
      field: 'delivery',
      hidden: !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.DELIVERY),

    },
    {
      label: 'branches.has-shipment',
      field: 'shipment',
      hidden: !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.SHIPMENT),
    },
    {
      label: 'branches.has-takeaway',
      field: 'pickup',
      hidden: !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.CAR_PICKUP),
    },
    {
      label: 'branches.has-schedule',
      field: 'schedule',
      hidden: !store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.SCHEDULE),
    },
    {
      label: 'fields.created-at',
      field: 'created_at',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    {
      // label: 'forms.edit',
      eventName: 'edit',
      icon: 'Edit2Icon',
      variant: 'warning',
    },
    {
      // label: 'forms.delete',
      eventName: 'delete',
      icon: 'Trash2Icon',
      variant: 'danger',
      disabled: (row: any) => row.main === 1,
    },
  ],
  filters: [],
}

export default BranchesTableConfig
