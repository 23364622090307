<template>
  <div class="mt-2">

    <b-alert
      v-if="!!closedAllError"
      :dismissible="true"
      :show="true"
      class="error-alert mb-1"
      variant="danger"
    >
      <span
        v-if="typeof closedAllError === 'string'"
        class="font-weight-bold text-underline text-danger cursor-pointer"
        @click="set24HoursToAll(closedAllError)"
      >
        {{ $t('shifts.set-24-hours-all') }}
      </span>
    </b-alert>

    <b-alert
      v-if="!!show24HoursWarning && !closedAllError"
      :dismissible="true"
      :show="true"
      class="error-alert mb-1"
      variant="warning"
    >
      <span>{{ $t('shifts.24-hours-notes') }}</span>
    </b-alert>

    <validation-observer ref="form">
      <opening-hours-list
        ref="general"
        :value="shifts"
        @input="setOpeningHours($event)"
        @error:closed-all="handleClosedAllError($event ? 'general' : null)"
        @warning:24hours-warning="handleShow24HoursWarning()"
      />
    </validation-observer>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import { BAlert } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import EditBranchMixin from '../mixinx/edit-branch-mixin'
import OpeningHoursList from './BranchShiftsForm'
import '@validations'
import { weekdays } from '../config/shift-constants'

export default {
  name: 'BranchOpeningHours',
  components: {
    ValidationObserver, OpeningHoursList, BAlert,
  },
  mixins: [EditBranchMixin],
  data() {
    return {
      shifts: {},
      closedAllError: null,
      show24HoursWarning: false,
      dirty: true,
      mounted: false,
    }
  },
  computed: {
    timezone() {
      return 'Asia/Kuwait'
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.shifts = cloneDeep(this.form?.shifts ?? {})
      if (!this.shifts[1]) {
        this.fillShift()
      } else {
        this.initShift()
        this.$forceUpdate()
      }
      setTimeout(() => {
        this.dirty = false
        this.mounted = true
      }, 500)
    },
    getOpeningHours() {
      return this.shifts
    },
    setOpeningHours(value) {
      this.shifts = value
      this.$forceUpdate()
      if (this.mounted) {
        this.dirty = true
        this.checkValidation()
      }
    },
    initShift() {
      const shifts = {}
      for (let dayIdx = 1; dayIdx <= 7; dayIdx += 1) {
        const defaultShift = {
          time_from: '', time_to: '', day_from: dayIdx, day_to: dayIdx,
        }
        const records = get(this.shifts, `${dayIdx}`, [{ ...defaultShift }])
          .map(shift => ({
            ...shift,
            time_from: (shift.time_from && shift.time_from === shift.time_to) ? '24' : shift.time_from,
          }))
        shifts[dayIdx] = {
          shifts: records,
          closed: get(this.shifts, `${dayIdx}.length`, 0) === 0,
        }
      }
      this.shifts = shifts
      this.$forceUpdate()
    },
    fillShift() {
      const shifts = {}
      for (let dayIdx = 1; dayIdx <= 7; dayIdx += 1) {
        shifts[dayIdx] = {
          shifts: [{
            time_from: '24', time_to: '00:00', day_from: dayIdx, day_to: Math.max(1, (dayIdx + 1) % 8),
          }],
          closed: false,
        }
      }
      this.setOpeningHours(shifts)
    },
    getOpeningShifts() {
      const typeShifts = Object.values(this.shifts ?? {})

      return typeShifts.reduce((p, {
        closed,
        shifts,
      }) => p.concat(closed ? [] : shifts.map((shift, idx) => {
        if (shifts.length <= 1) return shift

        const firstShift = (idx === 0 ? shift : shifts[idx - 1]) ?? {}
        const secondShift = (idx === 1 ? shift : shifts[idx + 1]) ?? {}
        const intersected = (firstShift.time_from <= secondShift.time_to) && (firstShift.time_to >= secondShift.time_from)

        if (!intersected) return shift

        if (idx === 0) {
          return { ...shift, time_to: shifts[idx + 1].time_to, day_to: shifts[idx + 1].day_to }
        }

        return { ...shift, should_remove: 1 }
      })
        .filter(shift => !shift.should_remove)
        .map(shift => {
          const time_from = shift.time_from === '24' ? shift.time_to : shift.time_from
          return {
            ...shift,
            time_from,
          }
        })), [])
    },
    async submit() {
      this.$forceUpdate()
      if (this.isInvalid() || !(await this.validate())) {
        this.closedAllError = true
        throw new Error()
      }
      return this.getOpeningShifts()
    },
    async validate(showError = false) {
      const ref = this.$refs.form

      const isValid = ref?.validate ? (await ref?.validate()) : false
      if (!isValid && showError) {
        this.closedAllError = 'general'
      }
      return isValid
    },
    isInvalid() {
      const days = (weekdays).map((_, i) => i + 1)
      const { shifts } = this

      return days.some(dayIdx => {
        const shift = shifts?.[dayIdx] ?? { shifts: [] }
        return shift.closed ? false : shift.shifts.some(({ time_from, time_to }) => !time_from || !time_to)
      })
    },
    checkValidation: debounce(function () {
      this.$emit('update:validation', !this.isInvalid())
    }, 500),
    handleClosedAllError(type) {
      this.closedAllError = null
      this.$nextTick(() => {
        this.closedAllError = type
      })
    },
    set24HoursToAll(type = 'general') {
      const ref = this.$refs[type]?.[0] ?? this.$refs[type]

      if (ref?.set24HoursToAll) {
        ref.set24HoursToAll()
        this.closedAllError = null
        this.handleShow24HoursWarning()
      }
    },
    handleShow24HoursWarning() {
      this.show24HoursWarning = false
      this.closedAllError = null
      this.$nextTick(() => {
        this.show24HoursWarning = true
      })
    },
    checkUnsavedChanges() {
      return this.dirty
    },
    reset() {
      this.init()
    },
  },
}
</script>
