import $http from '@/libs/axios'

class BranchAPIs {
  static async delete(id: number) {
    return (await $http.delete(`branches/${id}`)).data
  }

  static async list() {
    return (await $http.get('branches')).data
  }

  static async process(data: any) {
    return (await $http.post('branches', data)).data
  }

  static async getById(id: string) {
    return (await $http.get(`branches/${id}`)).data
  }
}

export default BranchAPIs
