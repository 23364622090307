<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div class="item-general-form mt-3">
      <b-row class="align-items-center">
        <template v-for="option in options">
          <b-col
            v-if="show(option.service)"
            :key="option.key"
            class="mb-1"
            md="6"
          >
            <b-form-checkbox
              v-model="form[option.key]"
              :unchecked-value="0"
              :value="1"
              switch
              @input="fetchZones($event,option.key)"
            >
              {{ $t('branches.' + option.text) }}
            </b-form-checkbox>

          </b-col>

        </template>
        <!-- <b-col
          v-if="show('delivery') && form.delivery"
          md="12"
        >
          <hr>
          <p class="font-weight-bold h4">
            {{ $t('branches.delivery-zones') }}
            <span class="text-muted h6">{{ `(${$t('branches.delivery-no-zones')})` }}</span>
          </p>
          <b-row class="mt-1">
            <b-col
              md="6"
              sm="12"
            >
              <ok-select
                v-model="city"
                :label="$t('branches.choose-city')"
                :options="cities"
                @input="setZones"
              />
            </b-col>
            <b-col
              md="6"
            />
            <b-col
              v-if="cityZones.length"
              md="3"
              class="d-flex align-items-center mt-1"
            >
              <b-form-checkbox-group
                v-model="branchZones"
                :options="cityZones"
              />
            </b-col>
          </b-row>
        </b-col>-->
      </b-row>
    </div>
  </validation-observer>
</template>

<script>
import { BCol, BFormCheckbox, BRow } from 'bootstrap-vue'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import LocalizationService from '@/services/localization-service'
import { BUSINESS_FEATURES } from '@/constants/business-types'
import EditBranchMixin from '../mixinx/edit-branch-mixin'

export default {
  name: 'BranchOrderSettingForm',
  components: {
    ValidationObserver,
    BRow,
    BCol,
    BFormCheckbox,
  },
  mixins: [EditBranchMixin],
  data() {
    return {
      options: [
        { text: 'has-delivery', key: 'delivery', service: BUSINESS_FEATURES.DELIVERY },
        { text: 'has-shipment', key: 'shipment', service: BUSINESS_FEATURES.SHIPMENT },
        { text: 'has-takeaway', key: 'pickup', service: BUSINESS_FEATURES.CAR_PICKUP },
        { text: 'has-internally', key: 'internally', service: BUSINESS_FEATURES.DINE_IN },
        { text: 'has-schedule', key: 'schedule_enabled', service: BUSINESS_FEATURES.SCHEDULE },
        { text: 'is-busy', key: 'is_busy', service: '' },
      ],
      cities: [],
      cityZones: [],
      branchZones: [],
      city: '',
    }
  },
  computed: {
    ...mapGetters({
      zones: 'delivery/zones',
    }),
  },
  mounted() {
    if (this.form?.delivery) {
      this.fetchZones(true)
      this.branchZones = this.form?.zones ?? []
    }
  },
  methods: {
    show(service) {
      if (!service) return true
      return this.$store.getters['businessTypes/isEnabled'](service) ?? true
    },
    prepareForSubmit() {
      const keys = ['delivery', 'shipment', 'pickup', 'schedule_enabled', 'is_busy', 'internally']
      const values = pick(this.form, keys)
      if (values.delivery) {
        values.zones = this.branchZones
      }
      return {
        ...values,
      }
    },
    async isValid() {
      return this.$refs.form.validate()
    },
    async fetchZones(fetch = false, label = 'delivery') {
      if (!fetch || label !== 'delivery') return
      this.fetching = true
      await this.$store.dispatch('delivery/FETCH_ZONES')
      this.fetching = false
      Object.keys(this.zones).forEach(city => {
        this.cities.push({ text: city, value: city })
        if (!this.city && (this.form?.zones ?? []).includes(this.zones[city][0].id)) {
          this.city = city
        }
      })
    },
    setZones(city) {
      this.zones[city].forEach(zone => this.cityZones.push({
        text: LocalizationService.getTranslate(zone, 'name', this.selectedLocale),
        value: zone.id,
      }))
    },
  },
}
</script>
