export default [
  { text: 'cities.damascus', value: 'damascus' },
  { text: 'cities.aleppo', value: 'aleppo' },
  { text: 'cities.tartous', value: 'tartous' },
  { text: 'cities.lattakia', value: 'lattakia' },
  { text: 'cities.hems', value: 'hems' },
  { text: 'cities.hama', value: 'dara' },
  { text: 'cities.rif-damascus', value: 'rif-damascus' },
  { text: 'cities.sweida', value: 'sweida' },
  { text: 'cities.dara', value: 'dara' },
  { text: 'cities.hasaka', value: 'hasaka' },
  { text: 'cities.idlep', value: 'idlep' },
  { text: 'cities.dirzor', value: 'dirzor' },
  { text: 'cities.raka', value: 'raka' },
  { text: 'cities.qonitra', value: 'qonitra' },
]
