import dayjs from 'dayjs'
import i18n from '@/libs/i18n'

require('dayjs/locale/ar')

let prev = dayjs().startOf('day').subtract(30, 'minutes')

export const shiftTimesOptions = ([...Array(48)]).map(() => {
  const time = prev.add(30, 'minutes')
  prev = time
  let t = time.locale('ar').format('h:mm A')
  if (i18n.locale === 'ar') {
    t = t.replaceAll('PM', 'م').replaceAll('AM', 'ص')
  }
  return { text: t, value: time.format('HH:mm') }
})

export const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
